module.exports = {
  "logOut": "Log ud",
  "myPage": "Mine oplysninger",
  "notLoggedIn": "Ikke logget ind",
  "confirmSubscriptionCancellation": "Du er ved at opsige dit abonnement. Vil du fortsætte?",
  "getInvoices": "Vis fakturaer",
  "changeCardDetails": "Rediger betalingsoplysninger",
  "cancelSubscription": "Opsig abonnement",
  "cancelSubscriptionTitle": "Opsig Vitec-abonnement",
  "cancelSubscriptionMessage": "Dette opsiger alle dine abonnementer, og du mister al adgang til vores produkter. Hvis du senere vælger at bruge vores produkter igen, bliver du nødt til at købe et nyt abonnement. Denne handling kan ikke fortrydes.",
  "eMail": "E-mail",
  "password": "Adgangskode",
  "givenName": "Fornavn",
  "familyName": "Efternavn",
  "address": "Adresse",
  "postcode": "Postnummer",
  "city": "By",
  "country": "Land",
  "phone": "Telefonnummer",
  "cancel": "Annuller",
  "save": "Gem",
  "edit": "Rediger",
  "paymentRelated": "Betalingsrelateret",
  "newCustomerTitle": "Opret en konto",
  "newCustomerDescription": "Tilmeld dig nu for at købe vores produkter og få adgang til læse- og skrivehjælp, uanset hvor du er.",
  "existingCustomerTitle": "Er du allerede kunde?",
  "existingCustomerDescription": "Få adgang til vores butik, se og rediger dine kontooplysninger og meget mere.",
  "updateCardInfo": "Opdater betalingsoplysninger",
  "updateCardInfoTitle": "Opdater betalingsoplysninger",
  "updateCardInfoMessage": "Vinduet med betalingsinformationer åbnes på en ny side.",
  "invoice": "Faktura",
  "download": "Hent",
  "show": "Vis",
  "date": "Dato",
  "price": "Pris",
  "buy": "Køb",
  "purchased": "Allerede købt",
  "remove": "Fjern",
  "add": "Tilføj",
  "unavailable": "Ikke tilgængelig",
  "readMore": "Læs mere",
  "shopPageTitle": "Køb",
  "mainProductName": "Vitec-abonnement",
  "mainProductLink": "https://www.vitec-mv.com/dk/sadan-hjaelper-vi/",
  "cdord": "CD-ORD",
  "cdordLink": "https://www.vitec-mv.com/dk/produkter/cd-ord/",
  "intoWords": "IntoWords",
  "intoWordsLink": "https://www.vitec-mv.com/dk/produkter/intowords/",
  "includesTitle": "Inkluderer",
  "landingPageTitle": "Hjem",
  "productPageTitle": "Produkter",
  "loginText": "Log ind med MVID",
  "menuText": "Menu",
  "productTitle": "{{product}} til {{platform}}",
  "storeDescription": "Du kan downloade appen direkte fra {{store}}.",
  "goTo": "Gå til {{store}}",
  "IntoWordsIosAppStoreLink": "https://apps.apple.com/dk/app/intowords/id554600691?l=da",
  "IntoWordsAndroidPlayStoreLink": "https://play.google.com/store/apps/details?id=com.mv_nordic.intowords&hl=da",
  "IntoWordsExtensionChromeStoreLink": "https://chrome.google.com/webstore/detail/intowords/nfphpgdlkoindbccbdmilopgeafllemo?hl=da",
  "productPageDescription": "For at bruge vores produkter skal du blot logge ind med MVID ved hjælp af de samme login-data, som du bruger til denne hjemmeside. Dit brugernavn er typisk din e-mail-adresse.",
  "downloadDirectly": "Du kan downloade appen direkte fra denne hjemmeside.",
  "expansions": "Udvidelser",
  "technicalTermsDescription": "Hvis du vil have bedre ordforslag, når du skriver komplicerede tekster, kan du tilføje lister med fagord til appen. Følg linket for at se den store samling af lister, som du kan vælge imellem.",
  "technicalTerms": "Fagord",
  "additionalVoicesDescription": "Hvis du vil bruge flere stemmer, kan du downloade et udvalg her.",
  "downloadAdditionalVoices": "Hent flere stemmer.",
  "languagePackDescription": "Hvis du vil bruge ordforslag og profiler på andre sprog, kan du downloade følgende sprogpakker og føje dem til din app.",
  "german": "Tysk",
  "danish": "Dansk",
  "swedish": "Svensk",
  "norwegian": "Norsk",
  "dutch": "Hollandsk",
  "italian": "Italiensk",
  "english": "Engelsk",
  "cdordDownloadLink": "https://softwaredistributionextra.vitec-mv.com/Programmer/CD-ORD/10.3/cd-ord-installer-da-10.exe",
  "technicalTermsLink": "https://www.vitec-mv.com/dk/download/",
  "additionalVoicesDownloadLink": "https://softwaredistributionextra.vitec-mv.com/CD-ORD_10.3/Stemmer/25%20stemmer.zip",
  "cdordMacDownloadLink": "https://softwaredistributionextra.vitec-mv.com/Programmer/CD-ORD-Mac/CD-ORD_da-Installer_v10.0.1.56.dmg",
  "germanPackDownloadLink": "https://softwaredistributionextra.vitec-mv.com/Programmer/CD-ORD-Mac/CD-ORD_language_de_v1.0.1.pkg",
  "danishPackDownloadLink": "https://softwaredistributionextra.vitec-mv.com/Programmer/CD-ORD-Mac/CD-ORD_language_da_v1.0.1.pkg",
  "swedishPackDownloadLink": "https://softwaredistributionextra.vitec-mv.com/Programmer/CD-ORD-Mac/CD-ORD_language_sv_v1.0.1.pkg",
  "norwegianPackDownloadLink": "https://softwaredistributionextra.vitec-mv.com/Programmer/CD-ORD-Mac/CD-ORD_language_no_v1.0.1.pkg",
  "dutchPackDownloadLink": "https://softwaredistributionextra.vitec-mv.com/Programmer/CD-ORD-Mac/CD-ORD_language_nl_v1.0.1.pkg",
  "italianPackDownloadLink": "https://softwaredistributionextra.vitec-mv.com/Programmer/CD-ORD-Mac/CD-ORD_language_it_v1.0.1.pkg",
  "englishPackDownloadLink": "https://softwaredistributionextra.vitec-mv.com/Programmer/CD-ORD-Mac/CD-ORD_language_en_v1.0.1.pkg",
  "french": "Fransk",
  "spanish": "Spansk",
  "frenchPackDownloadLink": "https://softwaredistributionextra.vitec-mv.com/Programmer/CD-ORD-Mac/CD-ORD_language_fr_v1.0.1.pkg",
  "spanishPackDownloadLink": "https://softwaredistributionextra.vitec-mv.com/Programmer/CD-ORD-Mac/CD-ORD_language_es_v1.0.1.pkg",
  "register": "Registrer",
  "NoInvoicesAvailable": "Ingen fakturaer tilgængelige",
  "UserCreated": "Din konto er nu oprettet, og du kan logge ind ved hjælp af de indtastede oplysninger",
  "UsernameTaken": "Brugernavn er optaget. Indtast venligst et nyt.\nHvis du har glemt din adgangskode, skal du klikke på Glemt adgangskode",
  "subscriptionStatus": "Abonnementsstatus",
  "billingStatusUnavailable": "Du har ikke noget aktivt abonnement.\nDu kan købe et {{link}}.",
  "linkTextHere": "her",
  "billingStatusCancelled": "Dit abonnement er annulleret. Du har adgang indtil {{endDate}}, og dit abonnement bliver ikke fornyet.",
  "billingStatusActive": "Dit abonnement er aktivt. Den aktuelle betalte periode varer indtil {{endDate}} og bliver automatisk fornyet, medmindre du annullerer dit abonnement.",
  "forgotPassword": "Glemt adgangskoden?",
  "forgotPasswordIntroduction": "Hvis du har glemt din adgangskode, kan du vælge en ny ved at indsende din e-mail-adresse her.",
  "forgotPasswordConfirmation": "Hvis e-mail-adressen var korrekt, vil du modtage en e-mail med instruktioner om, hvordan du nulstiller din adgangskode.",
  "applyForNewPasswordButtonText": "Send e-mail",
  "updatePasswordIntroduction": "Angiv din nye adgangskode",
  "updatePasswordButtonText": "Opdater adgangskode",
  "PasswordResetLinkExpired": "Linket til nulstilling af adgangskode er udløbet. Anmod om et nyt link til nulstilling af din adgangskode.",
  "PasswordUpdated": "Din adgangskode er nu opdateret. Du vil blive omdirigeret til login-siden.",
  "catchphrase": "Alle kan læse og skrive",
  "verifyAccountTitle": "Bekræft din konto",
  "AccountVerified": "Din konto er nu bekræftet, og du kan logge ind ved hjælp af din email og adgangskode.",
  "AccountNotVerified": "Din konto er ikke bekræftet korrekt. Prøv at registrere dig igen.",
  "UserCreatedStep1": "Du vil modtage en e-mail for at fuldføre din registrering.",
  "newPassword": "Ny adgangskode",
  "noAccess": "Du har i øjeblikket ikke adgang til vores produkter. For at bruge vores produkter skal du købe et abonnement.",
  "UserUpdated": "Dine personlige oplysninger er blevet opdateret.",
  "InfoUpdated": "Opdateringen lykkedes",
  "ok": "OK",
  "confirmChoice": "Er du sikker?",
  "registrationSucceededTitle": "Registreringen lykkedes",
  "registrationFailedTitle": "Registreringen mislykkedes",
  "findUs": "Find os",
  "vitecAddress": "Lucernemarken 17, 5260 Odense",
  "vitecAddressLink": "https://www.google.com/maps/dir/%27%27/Lucernemarken+17,+5260+Odense,+Danmark/@55.3705579,10.3947745,16z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x464d20657b3ab7c5:0x6ebe11b62d544c6f!2m2!1d10.399066!2d55.370558",
  "contactUs": "Kontakt os",
  "contactAndSupport": "Kontakt og support",
  "contactAndSupportLink": "https://vitec-mv.com/dk/support/",
  "sendEmail": "Send e-mail",
  "mailtoSales": "salg.mv@vitecsoftware.com",
  "callUs": "Ring til os",
  "callUsLink": "65 91 80 22",
  "aboutUs": "Om os",
  "aboutVitecMV": "Om Vitec MV",
  "aboutVitecMVLink": "https://vitec-mv.com/dk/om-vitec-mv/",
  "vacancies": "Job hos Vitec MV",
  "vacanciesLink": "https://vitec-mv.com/dk/om-vitec-mv/job-hos-vitec-mv/",
  "termsAndConditions": "Betingelser og vilkår",
  "termsAndConditionsLink": "https://vitec-mv.com/dk/betingelser-og-vilkar/",
  "followUs": "Følg os",
  "followUsOnFacebook": "Følg os på Facebook",
  "followUsOnFacebookLink": "https://www.facebook.com/Vitecmvdanmark",
  "followUsOnLinkedin": "Følg os på LinkedIn",
  "followUsOnLinkedinLink": "https://www.linkedin.com/company/vitec-mv",
  "followUsOnInstagram": "Følg os på Instagram",
  "followUsOnInstagramLink": "https://www.instagram.com/vitecmv/",
  "deleteAccountTitle": "Slet konto",
  "deleteAccountMessage": "Hvis du virkelig ønsker at slette din konto, bedes du ringe til vores supportteam eller sende os en e-mail.",
  "productsPageDescription": "Køb et abonnement for at få adgang til vores produkter, og download vores produkter for at begynde at bruge dem.",
  "myPageDescription": "Få adgang til dine kontodata og dine fakturerings-oplysninger.",
  "SubscriptionCancelledTitle": "Abonnement opsagt",
  "SubscriptionCancelled": "Dit abonnement er nu opsagt og adgangen til produkterne vil ophøre ved udgangen af din faktureringsperiode.",
  "Consent": "Jeg accepterer hermed, at Vitec MV A/S opbevarer mine oplysninger, jvf vores",
  "Newsletter": "Tilmeld til vores nyhedsbrev",
  "EulaUrl": "https://vitec-mv.com/dk/betingelser-og-vilkar/slutbrugeraftale-eula/",
  "LicenseAgreement": " slutbrugeraftale.",
  "educationalMaterialPageTitle": "Undervisnings-materiale",
  "educationalMaterialPageDescription": "Lær hvordan du bruger vores produkter endnu mere effektivt.",
  "educationalMaterialIntro": "Lorem ipsum dolor sidder amet, consectetur adipiscing elit. Proin malesuada vitae magna i tincidunt. Nullam porta dolor ut malesuada ultricies. Sed non orci tincidunt, vestibulum dolor vitae, rutrum tortor. Mauris commodo ullamcorper dictum. Nam ac pharetra magna, ikke pulvinar elit. Vivamus at diam ut augue euismod rhoncus ut ut dolor. Duis pharetra, turpis id porta facilisis, dolor libero pellentesque massa, eu pulvinar tortor magna vel metus. Maecenas risus augue, semper hendrerit facilisis a, commodo et risus. Mauris porttitor euismod malesuada. Quisque tincidunt, sapien eget facilisis rhoncus, ipsum lectus varius justo, quis congue erat nisl hendrerit tellus. Aliquam sed risus eros. Maecenas pretium elit nec ipsum molestie, ved gravida nulla dapibus.",
  "educationalMaterialIntroVideoLink": "https://vimeo.com/87110435",
  "startEducationalMaterialTitle": "Kom godt i gang",
  "continueLessonsTitle": "Fortsæt med din opgave",
  "reset": "Nulstil",
  "getPreviousAnswers": "Se tidligere svar",
  "userGroupSchool": "Grundskole",
  "classAndPlatfromSelection": "Vælg klassetrin og platform",
  "classDescription": "Vælg den gruppe, der passer bedst til din klassetrin, eller vælg en anden gruppe for lettere eller vanskeligere eksempelsætninger.",
  "platformDescription": "Vælg det produkt og den platform, du vil bruge.",
  "start": "Start",
  "chosenValuesDescription": "Du har valgt klassetrin {{schoolYearRange}} og du er nået til trin {{category}}, opgave {{lessonNumber}}.",
  "continue": "Fortsæt",
  "eduPlatformTitle": "Undervisnings-materiale til {{platform}}",
  "schoolYearRange": "klassetrin",
  "platform": "platform",
  "category": "trin",
  "lesson": "opgave",
  "progressDescription": "trin {{level}} , opgave {{lessonNumber}}",
  "educationalMaterialIntroTitle": "Hvad er Vitec MV's Undervisnings-materiale?",
  "mainProductPrice": "",
  "teacherGuide": "Lærervejledning",
  "teacherGuideGeneralDescription": "I undervisningsmaterialet stifter eleven bekendtskab med programmets funktioner samt øvrige strategier og værktøjer, som er relevante for den ordblinde elevs skriftlige arbejde. Materialet forudsætter, at eleven arbejder i enten Google Docs eller Word 365, bruger Adobe Acrobat Reader til arbejdet med PDF-filer og at eleven har adgang til NOTA. Er dette ikke tilfældet, må læreren foretage en selektering i hvilke opgaver, der ikke kan løses. Undervisningsmaterialet består af fire trin, som gennemføres kronologisk, da de har en stigende faglig progression.",
  "specificGuidesTitle": "Se specifikke vejledninger her:",
  "ITWGuideForYoungerUsers": "IntoWords Cloud til 2. til 4. klasse",
  "ITWGuideForOlderUsers": "IntoWords Cloud til 5. til 10. klasse",
  "CDordGuideForYoungerUsers": "CD-ORD til 2. til 4. klasse",
  "CDordGuideForOlderUsers": "CD-ORD til 5. til 10. klasse",
  "progressAlertTitle": "Fejl i gemme-processen",
  "progressAlertMessage": "Der opstod en intern fejl, og dine fremskridt kunne ikke gemmes.",
  "deleteProgressButtonTitle": "Slet fremskridt",
  "deleteProgressConfirmationMessage": "Er du sikker på, at du vil slette dine fremskridt og starte forfra? Dette kan ikke fortrydes.",
  "progressDeletedTitle": "Fremskridt slettet",
  "progressDeletedMessage": "Dine fremskridt er blevet slettet, og du kan nu starte forfra.",
  "errorTitle": "Der er opstået en fejl",
  "progressFailedMessage": "Der er opstået en fejl, og det vil ikke være muligt at gemme dine fremskridt.",
  "next": "Næste",
  "templatesIntroTitle": "Hvad er skrive-skabeloner?",
  "templatesIntro": "Lorem ipsum dolor sidder amet, consectetur adipiscing elit. Proin malesuada vitae magna i tincidunt. Nullam porta dolor ut malesuada ultricies. Sed non orci tincidunt, vestibulum dolor vitae, rutrum tortor. Mauris commodo ullamcorper dictum. Nam ac pharetra magna, ikke pulvinar elit. Vivamus at diam ut augue euismod rhoncus ut ut dolor. Duis pharetra, turpis id porta facilisis, dolor libero pellentesque massa, eu pulvinar tortor magna vel metus. Maecenas risus augue, semper hendrerit facilisis a, commodo et risus. Mauris porttitor euismod malesuada. Quisque tincidunt, sapien eget facilisis rhoncus, ipsum lectus varius justo, quis congue erat nisl hendrerit tellus. Aliquam sed risus eros. Maecenas pretium elit nec ipsum molestie, ved gravida nulla dapibus.",
  "templatesIntroVideoLink": "https://vimeo.com/87110435",
  "writingTemplates": "Skrive-skabeloner",
  "noTemplatesFound": "No templates found",
  "noTemplatesFoundMessage": "We are regularly updating our templates, so please be patient and try again at a later time. You can also change to a different language to start using templates immediately.",
  "chooseTemplate": "Choose a writing template",
  "genre": "Genre:",
  "templateLoader": "Indlæser skabelon",
  "templateLoadingErrorMessage": "Du vil blive omdirigeret til skabelonvalget.\nKontakt vores supportteam, hvis fejlen fortsætter."
}
;