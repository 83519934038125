import React from "react";
import "../../CSS/ModuleIntroStyle.css";
import ReactPlayer from "react-player/vimeo";
const TemplateIntro = ({ title, description, videoLink }) => {
  return (
    <div className="module-intro-div">
      <h2>{title}</h2>
      <p>{description}</p>
      <ReactPlayer className="module-intro-video" url={videoLink} />
    </div>
  );
};
export default TemplateIntro;
