/**
 * We're trying to follow the quick start guide:
 * {https://react.i18next.com/guides/quick-start}
 */

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import da from "../locale/da.json";
import en from "../locale/en.json";
import nb from "../locale/nb.json";
import sv from "../locale/sv.json";

// Link the translation files to a language.
const resources = {
  English: {
    translation: en
  },
  Danish: {
    translation: da
  },
  Norwegian: {
    translation: nb
  },
  Swedish: {
    translation: sv
  }
};

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next.
  .init({
    resources,
    lng: "en",

    keySeparator: false, // We do not use keys in form "messages.welcome".

    interpolation: {
      escapeValue: false // React already safes from xss.
    }
  });

export default i18n;
