import React, { lazy, useEffect, useState, useMemo, Suspense } from "react";
import ReactDom from "react-dom";
import { Router } from "@reach/router";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";

// Services
import i18n from "./i18n";
import { getCookies, setCookie } from "./service/cookieHandling";
import { useTranslation } from "react-i18next";
// Custom components
import Home from "./Pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { LoginStateProvider } from "./Context/LogInStateContext";
import { UserProvider } from "./Context/UserContext";

import { config } from "./config";
import {
  isSessionValid,
  purgeSessionCookie,
  getCookieSessionId,
  setCookieSession
} from "./service/authStorage";

import homeIcon from "./img/home.svg";
import shopIcon from "./img/apps.svg";
import profileIcon from "./img/profile.svg";
import templatesIcon from "./img/templates.svg";
import eduIcon from "./img/education.svg";

import { subscriptionService, signonService } from "./api/service";
import { serviceConfigurator } from "./service/ServiceConfigurator";
import Loader from "./components//Loader";

// Dynamically loaded pages.

const SuccessPage = lazy(() => import("./Pages/SuccessPage"));
const CancelPage = lazy(() => import("./Pages/CancelPage"));
const AccountPage = lazy(() => import("./Pages/AccountPage"));
const ResetPasswordPage = lazy(() => import("./Pages/ResetPasswordPage"));
const ConfirmAccountPage = lazy(() => import("./Pages/ConfirmAccountPage"));
const ProductPage = lazy(() => import("./Pages/ProductPage"));

const EduPage = lazy(() => import("./Pages/EduPage"));
const EduLessonPage = lazy(() => import("./Pages/EduLessonPage"));
const TeacherGuidePage = lazy(() => import("./Pages/TeacherGuidePage"));
const TemplatesLandingPage = lazy(() => import("./Pages/TemplatesLandingPage"));
const TemplatePage = lazy(() => import("./Pages/TemplatePage"));
// The following comment is a requirement for using an image of the android robot logo.
/**
 * The Android robot is reproduced or modified from work created and shared by Google
 * and used according to terms described in the Creative Commons 3.0 Attribution License.
 */

/**
 * This is the base of the app. From here, all functionality is delegated.
 */
const App = () => {
  var sessionId = getCookieSessionId();
  var uid = null;
  const { t } = useTranslation();
  const [isLoggedIn, setLoginState] = useState(false);
  const loginState = useMemo(
    () => ({ isLoggedIn, updateLoginState: setLoginState }),
    [isLoggedIn, setLoginState]
  );

  const [userData, setUserData] = useState({});
  const userState = useMemo(() => ({ userData, updateUserData: setUserData }), [
    userData,
    setUserData
  ]);

  const menuItems = [
    {
      link: "/",
      title: t("landingPageTitle").toUpperCase(),
      icon: homeIcon,
      description: ""
    },
    {
      link: "products",
      title: t("productPageTitle").toUpperCase(),
      icon: shopIcon,
      description: t("productsPageDescription")
    },
    {
      link: "templates",
      title: t("writingTemplates").toUpperCase(),
      icon: templatesIcon,
      description: "Improve your writing by using our templates."
    },
    {
      link: "undervisningsmateriale",
      title: t("educationalMaterialPageTitle").toUpperCase(),
      icon: eduIcon,
      description: t("educationalMaterialPageDescription")
    },
    {
      link: "userData",
      title: t("myPage").toUpperCase(),
      icon: profileIcon,
      description: t("myPageDescription")
    }
  ];

  const [loaderState, setLoaderState] = useState(false);

  useEffect(() => {
    if (isSessionValid()) {
      sessionId = getCookieSessionId();
    }
    if (!sessionId) {
      let capture = /[?]SessionID[=](.+)$/.exec(window.location.href);
      if (capture && typeof capture[1] !== "undefined") {
        sessionId = capture[1];
      }
    }

    if (sessionId) {
      setLoaderState(true);
      serviceConfigurator(sessionId);

      signonService
        .get()
        .getSecret(config.api.clientId)
        .then(
          secretResponse => {
            let secret = secretResponse.data;
            serviceConfigurator(sessionId, secret);

            signonService
              .get()
              .whoami()
              .then(
                whoamiresponse => {
                  // Extracting the users uuid. It is prefixed by login type and ||
                  const searchTerm = "|";
                  uid = whoamiresponse.data.Uid.substring(
                    whoamiresponse.data.Uid.lastIndexOf(searchTerm) + 1
                  );
                  // Getting the users information from the backend
                  let subscriptionsService = subscriptionService.get();
                  return getUserInfoFromService(subscriptionsService, uid)
                    .then(result => {
                      result.username = result.email;
                      setUserData(result);
                      setLoginState(true);
                      setLoaderState(false);
                    })
                    .catch(() => {
                      setLoaderState(false);
                    });
                },
                () => handleAuthFailure()
              );
          },
          () => handleAuthFailure()
        );

      setCookieSession(sessionId);
    }
  }, []);

  function handleAuthFailure() {
    setLoaderState(false);
    purgeSessionCookie();
  }

  /**
   * Checking if we are logged in when loading the page.
   * If user is already logged in we redirect to the account-page
   */
  useEffect(() => {
    if (isSessionValid()) {
      setLoginState(true);
    }
  }, []);

  useEffect(() => {
    /**
     * Set the language of the website.
     */
    const supportedLangagues = ["Norwegian", "Swedish", "Danish", "English"];

    let defaultLanguage = getCookies().webshopLanguage ?? "";
    getCookieSessionId();

    // If we found an unsupported language or none at all, we check the browser language.
    if (supportedLangagues.includes(defaultLanguage) === false) {
      const language = navigator.language;
      if (
        language.includes("nn") || // Nynorsk
        language.includes("nb") || // Bokmal
        language.includes("no") // Norwegian
      ) {
        defaultLanguage = "Norwegian";
      } else if (language.includes("sv")) {
        defaultLanguage = "Swedish";
      } else if (language.includes("da")) {
        defaultLanguage = "Danish";
      } else {
        defaultLanguage = "English";
      }
      // Since we now have a new default language, we save it as a session cookie.
      setCookie({
        name: "webshopLanguage",
        value: defaultLanguage,
        options: "path=/;secure"
      });
    }
    i18n.changeLanguage(defaultLanguage);
  }, []);

  /**
   * This method calls the MVIDAPI via mvid-admin-library to retrieve user information
   * @param {*} service, the subscription service to use
   * @param {*} userUuid, the Uuid of the user to get information for
   */
  const getUserInfoFromService = (service, userUuid) => {
    return service.getSubscription(userUuid).then(response => response.data);
  };

  return (
    <LoginStateProvider value={loginState}>
      <UserProvider value={userState}>
        <div className="main">
          <Helmet>
            <title>Vitec MV - Portal</title>
          </Helmet>
          <Header menuItems={menuItems} />

          <div className="content-area">
            <Loader isVisible={loaderState} />
            <Suspense fallback={<Loader isVisible="true" />}>
              <Router>
                <EduPage path="/" />
                <Home
                  path="/home"
                  isLoggedIn={loginState.isLoggedIn}
                  menuItems={menuItems.slice(1)}
                />
                {/* <SubscriptionPage path="orderPage" /> */}
                <SuccessPage path="success/:userId" />
                <CancelPage path="cancel/:userId" />
                <AccountPage path="userData" />
                <ProductPage path="products" />
                <ResetPasswordPage path="reset/:id" />
                <ConfirmAccountPage path="confirm/:id" />
                <EduPage path="edu" />
                <EduLessonPage path="edu/lessons" />
                <TeacherGuidePage path="edu/guide-for-teachers" />
                <TemplatesLandingPage path="templates" />
                <TemplatePage path="templates/template" />
                <ConfirmAccountPage path="confirm/:id" />
                <EduPage path="undervisningsmateriale" />
                <EduLessonPage path="undervisningsmateriale/lessons" />
                <TeacherGuidePage path="undervisningsmateriale/guide-for-teachers" />
              </Router>
            </Suspense>
          </div>
          <Footer />
        </div>
      </UserProvider>
    </LoginStateProvider>
  );
};

ReactDom.render(<App />, document.getElementById("root"));
