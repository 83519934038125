import React from "react";
import ReactLoader from "react-loader-spinner";

import "../../CSS/LoaderStyle.css";
const Loader = ({ isVisible = true }) => {
  return (
    <ReactLoader
      className="loader-component"
      visible={isVisible}
      type="BallTriangle"
      color="#961932"
      height={100}
      width={100}
    />
  );
};
export default Loader;
