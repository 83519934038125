import React from "react";
import "../../CSS/AlertBoxStyle.css";
import alertLogo from "../img/cautionSign.svg";
import smileyLogo from "../img/smiley.svg";
import errorLogo from "../img/error.svg";
import { useTranslation } from "react-i18next";

/**
 * An styled popup that can be used as a better alternative to alerts and confirms.  The popup always has a title area (with optional title) with a caution sign on the side.
 * Theres an area for an optional message, an optional button, and a default button.
 *
 * @param {Object} param0 All optional information for the Alert box.
 * @param {String} param0.style An optional style.
 *    If "GOOD" is chosen, the title includes a green smiley,
 *    if "NEUTRAL" is chosen, the title includes a yellow warning sign,
 *    if "ERROR" is chosen, a red alert sign is shown. In all other cases (including no style provided),
 *    no image will be shown in the title.
 * @param {String} param0.title The optional title of the alert box. If none is provided, there will still be a title area with a caution sign on the side.
 * @param {String} param0.message The optional message of the alert box. If none is provided, there will still be an empty message area.
 * @param {String} param0.optionalButtonTitle The optional title of the left button. If none is provided, this button will not be shown.
 * @param {Function} param0.optionalButtonAction The optional function that is run when the optional button is pressed. If no title is provided for the optional button, this function will not be run. If the title is provided, but this function is not, the button will do nothing.
 * @param {String} param0.defaultButtonTitle The optional title of the default button. If none is provided, "OK" will be used.
 * @param {Function} param0.defaultButtonAction The optional function that is run when the default button is pressed. If none is provided, the button will do nothing. In any case, the button will be shown.
 */
const AlertBox = ({
  style = null,
  title = null,
  message = null,
  optionalButtonTitle = null,
  optionalButtonAction = null,
  defaultButtonTitle = null,
  defaultButtonAction = null
}) => {
  const { t } = useTranslation();
  return (
    <div className="general-alert-box">
      {style === "GOOD" ? (
        <h1>
          <img className="good-style" src={smileyLogo} alt="" />
          <span>{title}</span>
        </h1>
      ) : style === "NEUTRAL" ? (
        <h1>
          <img className="neutral-style" src={alertLogo} alt="" />
          <span>{title}</span>
        </h1>
      ) : style === "ERROR" ? (
        <h1>
          <img className="error-style" src={errorLogo} alt="" />
          <span>{title}</span>
        </h1>
      ) : (
        <h1>
          <span>{title}</span>
        </h1>
      )}
      <div className="alert-body">
        <p className="alert-message">{message}</p>
        <div className="alert-button-div">
          {optionalButtonTitle === null ? null : (
            <button onClick={optionalButtonAction}>
              {optionalButtonTitle}
            </button>
          )}

          <button onClick={defaultButtonAction}>
            {defaultButtonTitle === null ? t("ok") : defaultButtonTitle}
          </button>
        </div>
      </div>
    </div>
  );
};
export default AlertBox;
