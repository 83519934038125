// React
import React, { useState } from "react";
// Services
import { useTranslation } from "react-i18next";
// Custom components
import PersonalInformationForm from "./PersonalInformationForm";
import AlertBox from "./AlertBox";
import Loader from "../components/Loader";
// CSS
import "../../CSS/AuthenticationStyle.css";

import { userManagementService } from "../api/service";
import { navigate } from "@reach/router";
import { serviceConfigurator } from "../service/ServiceConfigurator";
// Images
import ambientImage from "../img/8.jpg";

/**
 * A component that handles the authentification of users, either by registrating new users or by logging in existing ones.
 *
 * @param {object} LoginAction
 * @param {Function} LoginAction.onLogin The function that is called when the user logged in.
 */
const Authentication = () => {
  const { t } = useTranslation();
  const [
    isFailedRegistrationAlertVisible,
    updateFailedRegistrationAlertVisibility
  ] = useState(false);
  const [
    isSuccessfulRegistrationAlertVisible,
    updateSuccessfulRegistrationAlertVisibility
  ] = useState(false);

  const [isLoaderVisible, updateLoaderVisibility] = useState(false);
  const [isInputDisabled, updateInputState] = useState(false);

  function createUserClicked(userInfo) {
    updateLoaderVisibility(true);
    updateInputState(true);
    serviceConfigurator();
    userManagementService
      .get()
      .createUserRequest(userInfo)
      .then(response => {
        updateLoaderVisibility(false);
        if (response.status === 200) {
          updateSuccessfulRegistrationAlertVisibility(true);
        }
      })
      .catch(error => {
        updateLoaderVisibility(false);
        if (error.status === 409) {
          updateLoaderVisibility(false);
          updateFailedRegistrationAlertVisibility(true);
        }
      });
  }

  return (
    <div className="entire-login-container">
      <Loader isVisible={isLoaderVisible} />
      {isFailedRegistrationAlertVisible ? (
        <AlertBox
          style="NEUTRAL"
          title={t("registrationFailedTitle")}
          message={t("UsernameTaken")}
          defaultButtonAction={() => {
            updateFailedRegistrationAlertVisibility(false);
            updateInputState(false);
          }}
        />
      ) : null}
      {isSuccessfulRegistrationAlertVisible ? (
        <AlertBox
          style="GOOD"
          title={t("registrationSucceededTitle")}
          message={t("UserCreatedStep1")}
          defaultButtonAction={() => {
            updateSuccessfulRegistrationAlertVisibility(false);
            navigate("/");
          }}
        />
      ) : null}
      <div className="image-container">
        <div className="image-text-block">
          <span>{t("catchphrase")}</span>
        </div>
        <img src={ambientImage} alt="" />
      </div>
      <div className="login-options-container">
        <h1>{t("newCustomerTitle")}</h1>
        <div className="registration-area">
          <p>{t("newCustomerDescription")}</p>
          <PersonalInformationForm
            isRegistration={true}
            userInfo={{
              username: "",
              password: "",
              givenName: "",
              surName: "",
              address: "",
              postCode: "",
              city: "",
              countryRegionCode: "",
              phonenumber: ""
            }}
            onCreateUser={createUserClicked}
            forceDisableEditMode={isInputDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default Authentication;
