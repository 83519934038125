import React from "react";
import "../../CSS/FooterStyle.css";
import facebookLogo from "../img/facebookLogo.svg";
import linkedInLogo from "../img/linkedinLogo.svg";
import instagramLogo from "../img/instagramLogo.svg";
import { useTranslation } from "react-i18next";

/**
 * A footer component that shows contact information, social media links, and more.
 */
const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="footer">
      <div className="footer-block">
        <h3>{t("findUs")}</h3>
        <div className="footer-block-content">
          <a
            href={t("vitecAddressLink")}
            rel="noopener noreferrer"
            target="_blank"
          >
            {t("vitecAddress")}
          </a>
        </div>
      </div>
      <div className="footer-block">
        <h3>{t("contactUs")}</h3>
        <div className="footer-block-content">
          <ul>
            <li>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={t("contactAndSupportLink")}
              >
                {t("contactAndSupport")}
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={"mailto:" + t("mailtoSales")}
              >
                {t("sendEmail")}
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={"tel:" + t("callUsLink")}
              >
                {t("callUs")}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-block">
        <h3>{t("aboutUs")}</h3>
        <div className="footer-block-content">
          <ul>
            <li>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={t("aboutVitecMVLink")}
              >
                {t("aboutVitecMV")}
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={t("vacanciesLink")}
              >
                {t("vacancies")}
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={t("termsAndConditionsLink")}
              >
                {t("termsAndConditions")}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-block">
        <h3>{t("followUs")}</h3>
        <div className="footer-block-content footer-some-links">
          <a
            href={t("followUsOnFacebookLink")}
            target="_blank"
            aria-label={t("followUsOnFacebook")}
            rel="noopener noreferrer"
            title={t("followUsOnFacebook")}
          >
            <img src={facebookLogo} alt="" />
          </a>
          <a
            href={t("followUsOnLinkedinLink")}
            target="_blank"
            aria-label={t("followUsOnLinkedin")}
            rel="noopener noreferrer"
            title={t("followUsOnLinkedin")}
          >
            <img src={linkedInLogo} alt="" />
          </a>
          {i18n.language === "Danish" ? (
            <a
              href={t("followUsOnInstagramLink")}
              target="_blank"
              aria-label={t("followUsOnInstagram")}
              rel="noopener noreferrer"
              title={t("followUsOnInstagram")}
            >
              <img src={instagramLogo} alt="" />
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Footer;
