import { isDevelopment } from "./checkEnvironment";
/**
 * Saving the cookie, and removing the secure option if in development mode.
 *
 * @param {object} param0 All the info necessary to set the cookie.
 * @param {string} param0.name The name of the cookie.
 * @param {string} param0.value The value that the cookie should be set to.
 * @param {string} param0.options All the options that the cookie should have.
 */
export const setCookie = ({ name, value, options }) => {
  // Cookie must not be secure if still in development, as the browser will not set secure cookies in (the unsafe) localhost.
  let appropriateOptions = options;
  if (isDevelopment()) {
    appropriateOptions = options.replace(";secure", "");
  }
  document.cookie =
    encodeURIComponent(name) +
    "=" +
    encodeURIComponent(value) +
    ";" +
    appropriateOptions;
};
/** 
    Retrieving all cookies and reformatting them for easier access.

    @returns An object whose properties are the cookie names. */
export const getCookies = () => {
  /* The cookies are stored in a long string, separated by semi-colon.
    In order to separate the cookies, we split at semi-colons,
    and then to get the individual values, we split at equal signs.*/
  let data = {};
  let cookies = document.cookie.split(";");
  if (cookies) {
    cookies.forEach(cookie => {
      const [key, value] = cookie.split("=");
      data[decodeURIComponent(key.trim())] = decodeURIComponent(value);
    });
  }
  return data;
};
