module.exports = {
  "logOut": "Log out",
  "myPage": "My information",
  "notLoggedIn": "Not logged in",
  "confirmSubscriptionCancellation": "You are about to cancel your subscription. Do you want to continue?",
  "getInvoices": "View invoices",
  "changeCardDetails": "Change payment details",
  "cancelSubscription": "Cancel subscription",
  "cancelSubscriptionTitle": "Cancel Vitec subscription",
  "cancelSubscriptionMessage": "This will cancel all your subscriptions and you will lose all access to our products. If you later choose to use our products again, you will have to buy a new subscription. This action cannot be undone.",
  "eMail": "E-mail",
  "password": "Password",
  "givenName": "Given name",
  "familyName": "Family name",
  "address": "Address",
  "postcode": "Postcode",
  "city": "City",
  "country": "Country",
  "phone": "Phone number",
  "cancel": "Cancel",
  "save": "Save",
  "edit": "Edit",
  "paymentRelated": "Payment-related",
  "newCustomerTitle": "Create an account",
  "newCustomerDescription": "Register now to buy our products and get access to reading and writing aids no matter where you are.",
  "existingCustomerTitle": "Already a customer?",
  "existingCustomerDescription": "Access our shop, view and edit your account details, and more.",
  "updateCardInfo": "Update payment details",
  "updateCardInfoTitle": "Update payment details",
  "updateCardInfoMessage": "The payment details window will open on a new page.",
  "invoice": "Invoice",
  "download": "Download",
  "show": "Show",
  "date": "Date",
  "price": "Price",
  "buy": "Buy",
  "purchased": "Already purchased",
  "remove": "Remove",
  "add": "Add",
  "unavailable": "Unavailable",
  "readMore": "Read more",
  "shopPageTitle": "Buy",
  "mainProductName": "Vitec subscription",
  "mainProductLink": "https://www.vitec-mv.com/dk/sadan-hjaelper-vi/",
  "cdord": "IntoWords Pro",
  "cdordLink": "https://www.vitec-mv.com/dk/produkter/cd-ord/",
  "intoWords": "IntoWords",
  "intoWordsLink": "https://www.vitec-mv.com/dk/produkter/intowords/",
  "includesTitle": "Includes",
  "landingPageTitle": "Home",
  "productPageTitle": "Products",
  "loginText": "Log in with MVID",
  "menuText": "Menu",
  "productTitle": "{{product}} for {{platform}}",
  "storeDescription": "You can download the app directly from the {{store}}.",
  "goTo": "Go to {{store}}",
  "IntoWordsIosAppStoreLink": "https://apps.apple.com/dk/app/intowords/id554600691",
  "IntoWordsAndroidPlayStoreLink": "https://play.google.com/store/apps/details?id=com.mv_nordic.intowords&hl=en",
  "IntoWordsExtensionChromeStoreLink": "https://chrome.google.com/webstore/detail/intowords/nfphpgdlkoindbccbdmilopgeafllemo?hl=en",
  "productPageDescription": "To use our products, you simply log in with MVID, using the same login data as you use for this site. Your username is typically your e-mail address.",
  "downloadDirectly": "You can download the app directly from this website.",
  "expansions": "Expansions",
  "technicalTermsDescription": "If you want better word predictions when writing complicated texts, you can add lists of technical terms to the app. Follow the link to see the large collection of lists that you can choose from.",
  "technicalTerms": "Technical terms",
  "additionalVoicesDescription": "If you want to use additional voices, you can download a selection here.",
  "downloadAdditionalVoices": "Download additional voices.",
  "languagePackDescription": "If you want to use word prediction and profiles in other languages, you can download the following language packs and add them to your app.",
  "german": "German",
  "danish": "Danish",
  "swedish": "Swedish",
  "norwegian": "Norwegian",
  "dutch": "Dutch",
  "italian": "Italian",
  "english": "English",
  "cdordDownloadLink": "https://softwaredistributionextra.vitec-mv.com/Programmer/CD-ORD/10.3/cd-ord-installer-da-10.exe",
  "technicalTermsLink": "https://www.vitec-mv.com/dk/download/",
  "additionalVoicesDownloadLink": "https://softwaredistributionextra.vitec-mv.com/CD-ORD_10.3/Stemmer/25%20stemmer.zip",
  "cdordMacDownloadLink": "https://softwaredistributionextra.vitec-mv.com/Programmer/CD-ORD-Mac/CD-ORD_da-Installer_v10.0.1.56.dmg",
  "germanPackDownloadLink": "https://softwaredistributionextra.vitec-mv.com/Programmer/CD-ORD-Mac/CD-ORD_language_de_v1.0.1.pkg",
  "danishPackDownloadLink": "https://softwaredistributionextra.vitec-mv.com/Programmer/CD-ORD-Mac/CD-ORD_language_da_v1.0.1.pkg",
  "swedishPackDownloadLink": "https://softwaredistributionextra.vitec-mv.com/Programmer/CD-ORD-Mac/CD-ORD_language_sv_v1.0.1.pkg",
  "norwegianPackDownloadLink": "https://softwaredistributionextra.vitec-mv.com/Programmer/CD-ORD-Mac/CD-ORD_language_no_v1.0.1.pkg",
  "dutchPackDownloadLink": "https://softwaredistributionextra.vitec-mv.com/Programmer/CD-ORD-Mac/CD-ORD_language_nl_v1.0.1.pkg",
  "italianPackDownloadLink": "https://softwaredistributionextra.vitec-mv.com/Programmer/CD-ORD-Mac/CD-ORD_language_it_v1.0.1.pkg",
  "englishPackDownloadLink": "https://softwaredistributionextra.vitec-mv.com/Programmer/CD-ORD-Mac/CD-ORD_language_en_v1.0.1.pkg",
  "french": "French",
  "spanish": "Spanish",
  "frenchPackDownloadLink": "https://softwaredistributionextra.vitec-mv.com/Programmer/CD-ORD-Mac/CD-ORD_language_fr_v1.0.1.pkg",
  "spanishPackDownloadLink": "https://softwaredistributionextra.vitec-mv.com/Programmer/CD-ORD-Mac/CD-ORD_language_es_v1.0.1.pkg",
  "register": "Register",
  "NoInvoicesAvailable": "No invoices available",
  "UserCreated": "Your account is now created and you can log in using the credentials entered",
  "UsernameTaken": "Username is taken. Please enter a new one.\nIf you have forgotten your password, click on Forgotten password",
  "subscriptionStatus": "Subscription status",
  "billingStatusUnavailable": "You have no active subscription.\nYou can buy one {{link}}.",
  "linkTextHere": "here",
  "billingStatusCancelled": "Your subscription is cancelled. You have access until {{endDate}}, and your subscription will not be renewed.",
  "billingStatusActive": "Your subscription is active. The current paid period lasts until {{endDate}} and is renewed automatically, unless you cancel your subscription.",
  "forgotPassword": "Forgot Password?",
  "forgotPasswordIntroduction": "If you've forgotten your password, you can choose a new one by submitting your e-mail address here.",
  "forgotPasswordConfirmation": "If the e-mail address was correct, you will receive an e-mail with instructions on how to reset your password.",
  "applyForNewPasswordButtonText": "Send e-mail",
  "updatePasswordIntroduction": "Enter your new password",
  "updatePasswordButtonText": "Update password",
  "PasswordResetLinkExpired": "The password reset link is expired. Please request a new link to reset your password.",
  "PasswordUpdated": "Your password is now updated. You will be redirected to the login page.",
  "catchphrase": "Everyone can read and write",
  "verifyAccountTitle": "Verify your account",
  "AccountVerified": "Your account is now verified and you can log in using your email and password.",
  "AccountNotVerified": "Your account was not verified correctly. Please try and register again.",
  "UserCreatedStep1": "You will receive an e-mail to complete your registration.",
  "newPassword": "New password",
  "noAccess": "You currently haven't got access to our products. To use our products, you have to buy a subscription.",
  "UserUpdated": "Your personal information has been updated successfully.",
  "InfoUpdated": "Update succeeded",
  "ok": "OK",
  "confirmChoice": "Are you sure?",
  "registrationSucceededTitle": "Registration succeeded",
  "registrationFailedTitle": "Registration failed",
  "findUs": "Find us",
  "vitecAddress": "Lucernemarken 17, 5260 Odense",
  "vitecAddressLink": "https://www.google.com/maps/dir/%27%27/Lucernemarken+17,+5260+Odense,+Danmark/@55.3705579,10.3947745,16z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x464d20657b3ab7c5:0x6ebe11b62d544c6f!2m2!1d10.399066!2d55.370558",
  "contactUs": "Contact us",
  "contactAndSupport": "Contact and support",
  "contactAndSupportLink": "https://vitec-mv.com/dk/support/",
  "sendEmail": "Send e-mail",
  "mailtoSales": "salg.mv@vitecsoftware.com",
  "callUs": "Call us",
  "callUsLink": "65 91 80 22",
  "aboutUs": "About us",
  "aboutVitecMV": "About Vitec MV",
  "aboutVitecMVLink": "https://vitec-mv.com/dk/om-vitec-mv/",
  "vacancies": "Vacancies at Vitec MV",
  "vacanciesLink": "https://vitec-mv.com/dk/om-vitec-mv/job-hos-vitec-mv/",
  "termsAndConditions": "Terms and Conditions",
  "termsAndConditionsLink": "https://vitec-mv.com/dk/betingelser-og-vilkar/",
  "followUs": "Follow us",
  "followUsOnFacebook": "Follow us on Facebook",
  "followUsOnFacebookLink": "https://www.facebook.com/Vitecmvdanmark",
  "followUsOnLinkedin": "Follow us on LinkedIn",
  "followUsOnLinkedinLink": "https://www.linkedin.com/company/vitec-mv",
  "followUsOnInstagram": "Follow us on Instagram",
  "followUsOnInstagramLink": "https://www.instagram.com/vitecmv/",
  "deleteAccountTitle": "Delete account",
  "deleteAccountMessage": "If you really wish to delete your account, please call our support team or e-mail us.",
  "productsPageDescription": "Buy a subscription to access to our products, and download our products to start using them.",
  "myPageDescription": "Access your account data and your billing information.",
  "SubscriptionCancelledTitle": "Subscription cancelled",
  "SubscriptionCancelled": "Your subscription is now cancelled and access to the products will stop at the end of your billing period.",
  "Consent": "I hereby accept that Vitec MV A/S stores my information according to our",
  "Newsletter": "Subscribe to our newsletter",
  "EulaUrl": "https://vitec-mv.com/dk/betingelser-og-vilkar/slutbrugeraftale-eula/",
  "LicenseAgreement": " license agreement.",
  "educationalMaterialPageTitle": "Educational Material",
  "educationalMaterialPageDescription": "Learn how to use our products even more efficiently.",
  "educationalMaterialIntro": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin malesuada vitae magna in tincidunt. Nullam porta dolor ut malesuada ultricies. Sed non orci tincidunt, vestibulum dolor vitae, rutrum tortor. Mauris commodo ullamcorper dictum. Nam ac pharetra magna, non pulvinar elit. Vivamus at diam ut augue euismod rhoncus ut ut dolor. Duis pharetra, turpis id porta facilisis, dolor libero pellentesque massa, eu pulvinar tortor magna vel metus. Maecenas risus augue, semper hendrerit facilisis a, commodo et risus. Mauris porttitor euismod malesuada. Quisque tincidunt, sapien eget facilisis rhoncus, ipsum lectus varius justo, quis congue erat nisl hendrerit tellus. Aliquam sed risus eros. Maecenas pretium elit nec ipsum molestie, at gravida nulla dapibus.",
  "educationalMaterialIntroVideoLink": "https://vimeo.com/87110435",
  "startEducationalMaterialTitle": "Get started",
  "continueLessonsTitle": "Continue with your lesson",
  "reset": "Reset",
  "getPreviousAnswers": "View previous answers",
  "userGroupSchool": "Primary school",
  "classAndPlatfromSelection": "Select school year range and platform",
  "classDescription": "Choose the group that matches your school year range best, or choose a different group for easier or more difficult sample sentences.",
  "platformDescription": "Choose the product and platform that you want to use.",
  "start": "Start",
  "chosenValuesDescription": "You have chosen school year range {{schoolYearRange}} and you have reached step {{category}}, lesson {{lessonNumber}}.",
  "continue": "Continue",
  "eduPlatformTitle": "Educational Material for {{platform}}",
  "schoolYearRange": "school year range",
  "platform": "platform",
  "category": "step",
  "lesson": "lesson",
  "progressDescription": "step {{level}}, lesson {{lessonNumber}}",
  "educationalMaterialIntroTitle": "What is Educational Material by Vitec MV?",
  "mainProductPrice": "",
  "teacherGuide": "Teacher guide",
  "teacherGuideGeneralDescription": "Through the educational material, the pupil will get acquainted with the functionality of the programs as well as with other strategies and tools that are relevant for a dyslexic pupil's written work. The material assumes that the pupil works in either Google Docs or Word 365, uses Adobe Acrobat Reader for working with PDF files, and that the pupil has access to NOTA. If this is not the case, the teacher must make a selection of which tasks cannot be solved. The teaching material consists of four steps, which are completed chronologically, as they have an increasing academic progression.",
  "specificGuidesTitle": "View specific guides here:",
  "ITWGuideForYoungerUsers": "IntoWords Cloud for grades 2 to 4",
  "ITWGuideForOlderUsers": "IntoWords Cloud for grades 5 to 10",
  "CDordGuideForYoungerUsers": "IntoWords Pro for grades 2 to 4",
  "CDordGuideForOlderUsers": "IntoWords Pro for grades 5 to 10",
  "progressAlertTitle": "Error during saving process",
  "progressAlertMessage": "An internal error occurred and your progress could not be saved.",
  "deleteProgressButtonTitle": "Delete progress",
  "deleteProgressConfirmationMessage": "Are you sure you want to delete your progress and start over again? This cannot be undone.",
  "progressDeletedTitle": "Progress deleted",
  "progressDeletedMessage": "Your progress has been deleted and you can now start over again.",
  "errorTitle": "An error occurred",
  "progressFailedMessage": "An error occurred and it won't be possible to save your progress.",
  "next": "Next",
  "templatesIntroTitle": "What are writing templates?",
  "templatesIntro": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin malesuada vitae magna in tincidunt. Nullam porta dolor ut malesuada ultricies. Sed non orci tincidunt, vestibulum dolor vitae, rutrum tortor. Mauris commodo ullamcorper dictum. Nam ac pharetra magna, non pulvinar elit. Vivamus at diam ut augue euismod rhoncus ut ut dolor. Duis pharetra, turpis id porta facilisis, dolor libero pellentesque massa, eu pulvinar tortor magna vel metus. Maecenas risus augue, semper hendrerit facilisis a, commodo et risus. Mauris porttitor euismod malesuada. Quisque tincidunt, sapien eget facilisis rhoncus, ipsum lectus varius justo, quis congue erat nisl hendrerit tellus. Aliquam sed risus eros. Maecenas pretium elit nec ipsum molestie, at gravida nulla dapibus.",
  "templatesIntroVideoLink": "https://vimeo.com/87110435",
  "writingTemplates": "Writing templates",
  "noTemplatesFound": "No templates found",
  "noTemplatesFoundMessage": "We are regularly updating our templates, so please be patient and try again at a later time. You can also change to a different language to start using templates immediately.",
  "chooseTemplate": "Choose a writing template",
  "genre": "Genre:",
  "templateLoader": "Loading template",
  "templateLoadingErrorMessage": "You will be re-directed to the template selection.\nPlease contact our support team if the error persists."
}
;